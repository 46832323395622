/*
    teal   #83D1C4
    purple #78517c
    orange #f17950

    coral #f97b55
    blue #40c6ff
    purp #5c42a8
*/

.navbar {
    background-color: #f97b55;
    
    /*height: 370px;*/
    height: 200px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.menu-bars {
    margin-left: 2rem;
    font-size: 2rem;
    background: none;
    background-color: #f97b55;
}

.nav-menu {
    background-color: #f97b55;
    /*background-color: #5c42a8;*/
    width: 350px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: -100%;
    transition: 850ms;
    overflow:scroll;

}

.nav-menu.active {
    left: 0;
    transition: 350ms;
}

.nav-text {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 8px 0px 8px 16px;
    list-style: none;
    height: 60px;
    
}

.nav-text a {
    text-decoration: none;
    color: #ffffff;
    font-family: Ubuntu; 
    font-size: 18px;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 4px;
}

.fa-fort-awesome {
    color: black;
}

.nav-text a:hover {
    background-color: #0de7ff;
}

.nav-menu-items {
    width: 100%;
}

.navbar-toggle {
    background-color: #f97b55;
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

span {
    margin-left: 16px;
}

.logo_pad {
    position: relative;
    padding-top: 60px;
}

.logo_text_pad {
    position: relative;
    top: -55px; /* This will move it 20px up */
}