@import url(https://fonts.googleapis.com/css2?family=Ubuntu:wght@300&display=swap);
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Ubuntu', sans-serif;
    font-weight: bold;
}

body {
    background-color: #0de7ff;
}

.alien,
.jason, 
.hinox,
.ecto_one,
.fire_mario,
.mana_beast,
.pokey,
.samus_aran,
.slash,
.venom,
.walker,
.wheel_gator,
.wily_castle,
.zombie {
    display: inline;
    vertical-align: top;
    height: 30vh;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    float: left;
    font-size: 3rem;
}

.pad_sprite_name {
    padding-top: 1em;
    padding-left: 8em;
}

.pad_chart_label {
    padding-top: 1em;
    padding-left: 8em;
    padding-bottom: 1em;
}

.pad_image {
    padding-left: 8em;
}

.pad_chart {
    padding-left: 8em;
}

.center {
    text-align: center;
    margin: auto;
    padding-left: 100;
    padding-top: -50px;
}

.nudge_left {
    position:relative; 
}

@font-face {
    font-family: 'SNES';
    src: local('SNES'), url(/static/media/SnesItalic-1G9Be.646e2419.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
        'embedded-opentype', and 'svg' */
}

@font-face {
    font-family: 'goblin';
    src: local('goblin'), url(/static/media/AGoblinAppears-o2aV.299320c4.ttf) format('truetype');
}

@font-face {
    font-family: 'mana';
    src: local('mana'), url(/static/media/ManaspaceRegular-ZJwZ.0d8565d9.ttf) format('truetype');
}

.snes_font {
    font-family: SNES;
    font-size: 12rem;
    color: #000;
}

.goblin_font {
    font-family: goblin;
    font-size: 3rem;
}

.goblin_font_sidebar {
    font-family: goblin;
    font-size: 1rem;
}

.mana_font {
    font-family: mana;
    font-size: 6rem;
}

.chart-container {
    background-color: #dadada;
}

.container {
    width: 100%;
    height: 100vh;
}

.dropdown {
    width: 400px;
    margin: 100px auto;
    position: relative;
}

.dropdown .dropdown-content {
    position: absolute;
    top: 110%;
    left: 0;
    padding: 10px;
    background: #fff;
    box-shadow: 3px 3px 10px 6px rgba(0, 0, 0, 0.06);
    font-weight: 500;
    color: #333;
    width: 95%;
}

::-webkit-scrollbar {
    width: 12px;
}

::-webkit-scrollbar-thumb {
    background: linear-gradient(transparent, #f97b55);
    border-radius: 6px;
}

.chart_background_gray {
    background-color: #dadada;
}

.chart_background_blue {
    background-color: #8ac5ff;
}

.chart_background_green {
    background-color: #ddf482;
}

.chart_background_black {
    background-color: #000000;
}
/*
    teal   #83D1C4
    purple #78517c
    orange #f17950

    coral #f97b55
    blue #40c6ff
    purp #5c42a8
*/

.navbar {
    background-color: #f97b55;
    
    /*height: 370px;*/
    height: 200px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: center;
            align-items: center;
}

.menu-bars {
    margin-left: 2rem;
    font-size: 2rem;
    background: none;
    background-color: #f97b55;
}

.nav-menu {
    background-color: #f97b55;
    /*background-color: #5c42a8;*/
    width: 350px;
    height: 100vh;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    position: fixed;
    top: 0;
    left: -100%;
    transition: 850ms;
    overflow:scroll;

}

.nav-menu.active {
    left: 0;
    transition: 350ms;
}

.nav-text {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: center;
            align-items: center;
    padding: 8px 0px 8px 16px;
    list-style: none;
    height: 60px;
    
}

.nav-text a {
    text-decoration: none;
    color: #ffffff;
    font-family: Ubuntu; 
    font-size: 18px;
    width: 95%;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    padding: 0 16px;
    border-radius: 4px;
}

.fa-fort-awesome {
    color: black;
}

.nav-text a:hover {
    background-color: #0de7ff;
}

.nav-menu-items {
    width: 100%;
}

.navbar-toggle {
    background-color: #f97b55;
    width: 100%;
    height: 80px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: center;
            align-items: center;
}

span {
    margin-left: 16px;
}

.logo_pad {
    position: relative;
    padding-top: 60px;
}

.logo_text_pad {
    position: relative;
    top: -55px; /* This will move it 20px up */
}
